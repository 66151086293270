body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #bbb1b9;
  border-radius: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.buttonHover:hover {
  background-color: white;
  color: white;
}
.buttonHover1:hover {
  background-color: white;
  color: white;
}
.overlayProject {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0.8;
  background-color: #1b9ce3;
}
.project-title-name {
  font-size: 20;
  text-align: "initial";
  color: black;
}
.project-title-name,
#title-project:hover {
  color: white;
}
.heading-logo {
  display: inline-grid;
  justify-items: center;
  padding: 5px;
  margin-left: -44px;
}
.career-coming-soon {
  border: 2px solid #d8ccff;
  padding-left: 43px;
  width: 50%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.btn-siteproject {
  position: absolute;
  display: none;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  color: black;
  bottom: 0;
  background-color: white;
  z-index: 2;
  cursor: pointer;
}
.textColor {
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
